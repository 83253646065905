
.page{
  margin-left: auto;
  margin-right: auto;
}

.page-header{
  display: flex;
  width: 100%;
  margin: 2em auto;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row-reverse;
  @include breakpoint(medium down){
    flex-direction: column-reverse;
    align-items: center;
  };
}

.page-title {
  img { width: auto; max-height: 42px; }
  @include breakpoint(medium down){
    margin-bottom: 1rem;
    padding: 0 2vw;
  };
  @include breakpoint(large up){
    margin: 0 1em 1px 0;
    padding-left: 0.9375rem;
  };
}
