
$global-border-radius: 7px;
$site-container-margin: (
  small: 2vw,
  medium: 4vw
);

@function site-container-margin($key) {
  @if map-has-key($site-container-margin, $key) {
    @return map-get($site-container-margin, $key);
  }
  @warn "Unkown '#{$key}' in $font-size-small.";
  @return null;
}
