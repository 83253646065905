$post-container-padding: 2em;
$post-container-padding--small: 1em;

.post-container{
  @include breakpoint(small down){
    margin-bottom: 1em;
  };
}

.post{
  margin: 0 0 -1px;
  padding: 0 $post-container-padding;
  border: 1px solid;
  @include breakpoint(small down){
    padding: 0 $post-container-padding--small;
  };
  &:first-of-type{
    border-top-left-radius: $global-border-radius;
    border-top-right-radius: $global-border-radius;
  }
  &:last-of-type{
    border-bottom-left-radius: $global-border-radius;
    border-bottom-right-radius: $global-border-radius;
  }
}

%post-margin-break{
  margin-left: -$post-container-padding;
  margin-right: -$post-container-padding;
  @include breakpoint(small down){
    margin-left: -$post-container-padding--small;
    margin-right: -$post-container-padding--small;
  };
};

.post-header{
  @extend %post-margin-break;
  position: relative;
  margin-bottom: $post-container-padding;
  padding: $post-container-padding;
  border-bottom: 1px dashed;
  @include breakpoint(small down){
    margin-bottom: $post-container-padding--small;
    padding: $post-container-padding--small;
  };
  &__title{
    margin: 0;
  }
  a {
    @extend %fill-overlay;
    &:before{ border-radius: $global-border-radius; }
    &:hover:before { background-color: rgba($yellow, 0.3); }
  }
}

.post-footer{
  @extend %post-margin-break;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: $post-container-padding/2;
  border-top: 1px dashed;
  font-size: rem-calc(13);
  @include breakpoint(small down){
    flex-direction: column;
    padding: $post-container-padding--small/2;
  };
  &__share{
    flex: 0 0 auto;
    margin-left: 0.5em;
    line-height: 24px;
    @include breakpoint(medium only){ flex: 1 1 0px; };
  }
  &__colophon{ margin: 0; }
}
