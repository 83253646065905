
body.bazar main{
  .page-header{ display: none; }
  a:not(.button) {
    color: #10613a;
    &:hover { color: #820d0d; }
  }
  h1 {
    overflow: hidden;
    margin-bottom: 30px;
    padding: 10px;
    border-bottom: 2px solid;
    letter-spacing: 0.5em;
    color: #103261; }
  h1:after {
    float: right;
    content: "Yurinoki St. Handmade Goods Bazar";
    white-space: nowrap;
    margin: 0 1em;
    letter-spacing: 1px;
    line-height: 40px;
    font-family: serif;
    font-size: 10px;
    opacity: 0.4; }
  h1.bazar-home {
    margin: 0;
    padding: 0;
    border: none; }
  h1.bazar-home:after {
      display: none; }
  section.columns {
    margin-top: 40px;
    margin-bottom: 40px; }
  section p,
  section li {
    font-size: 1.3125rem; }
}
body.bazar{
  .main-nav,
  .branch-nav {
    margin-bottom: 10px; }
    .main-nav ul,
    .branch-nav ul {
      overflow: hidden;
      margin: 0; }
    .main-nav a:hover,
    .branch-nav a:hover {
      opacity: 0.8; }
  .main-nav li {
    float: left;
    width: 25%;
    list-style-type: none;
    font-size: 0.875rem; }
    .main-nav li a {
      display: block;
      background-color: #103261;
      border: 1px solid white;
      line-height: 44px;
      text-align: center;
      color: white; }
    .main-nav li.current a {
      background-color: #e6ebed;
      color: #103261; }
    @media only screen and (max-width: 40em) {
      .main-nav li {
        width: 50%; } }
  .branch-nav {
    margin-bottom: 40px; }
    .branch-nav h2 {
      position: relative; z-index: 1;
      display: inline-block;
      margin-bottom: -2px;
      padding: 10px 10px 8px;
      background-color: #e6ebed;
      border: 2px solid;
      border-bottom: none;
      font-size: 0.875rem;
      line-height: 1;
      color: #103261; }
    .branch-nav ul {
      margin-top: -1px;
      padding: 10px;
      background-color: #e6ebed;
      border: 2px solid;
      color: #103261; }
    .branch-nav li {
      float: left;
      width: 25%;
      list-style-type: none;
      font-size: 0.875rem; }
      .branch-nav li a {
        display: block;
        margin-left: 4px;
        background-color: white;
        border-bottom: 1px solid;
        border-right: 1px solid;
        line-height: 44px;
        text-align: center;
        color: #103261; }
      .branch-nav li.current a {
        border: none;
        border-top: 1px solid #59717b;
        border-left: 1px solid #59717b;
        background-color: #c8d3d8; }
      @media only screen and (max-width: 40em) {
        .branch-nav li {
          width: 50%; } }
  hr.flow-hr {
    overflow: hidden;
    width: 40px;
    margin: 10px auto 20px;
    padding-bottom: 20px;
    border: none; }
    hr.flow-hr:before, hr.flow-hr:after {
      content: '';
      display: block;
      float: left;
      width: 50%;
      height: 20px;
      *zoom: 1;
      filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFFFFFFF', endColorstr='#FFFFFFFF'); }
    hr.flow-hr:before {
      background-image: -moz-linear-gradient(left bottom, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%);
      background-image: -webkit-linear-gradient(left bottom, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%);
      background-image: linear-gradient(to right top, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%); }
    hr.flow-hr:after {
      background-image: -moz-linear-gradient(right bottom, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%);
      background-image: -webkit-linear-gradient(right bottom, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%);
      background-image: linear-gradient(to left top, #ffffff 48%, #103261 51%, #103261 54%, #ffffff 57%); }
  .bazar-info {
    overflow: hidden;
    margin: 20px 0 20px;
    color: #103261;
    font-size: 1.25rem; }
    .bazar-info li {
      float: left;
      width: 33.33%;
      padding: 1px;
      list-style-type: none; }
      .bazar-info li:nth-child(1) {
        width: 40%; }
      .bazar-info li:nth-child(2) {
        width: 20%; }
      .bazar-info li:nth-child(3) {
        width: 40%; }
      .bazar-info li strong {
        display: inline-block;
        padding: 0.25em 2em;
        border: 2px solid;
        font-size: 0.75rem; }
        .bazar-info li strong:after {
          display: block;
          content: ""; }
  .bazar-concept {
    margin: 40px 0;
    font-size: 1.25rem;
    letter-spacing: 0.25em;
    color: #103261; }
  table {
    width: 100%;
    border-left: none;
    border-right: none;
    border-collapse: collapse;
    border-spacing: 0; }
    table th, table td {
      border: 2px solid #103261;
      @include breakpoint(small down){
        display: block;
        margin-bottom: -2px;
      };
    }
    table th {
      vertical-align: top;
      white-space: nowrap;
      color: #103261; }
      table th strong {
        font-size: 0.75rem;
        color: #c05412; }
  td input[type="text"] {
    display: inline-block;
    width: auto;
    margin: 0;
    @include breakpoint(small down){
      width: 100%;
    };
    br{
      margin-top: 0.25em;
    }
  }
  input[type="submit"] {
    margin: 1em auto;
    padding: 0 2em;
    background-color: #103261;
    border: 1px solid white;
    border-radius: 4px;
    line-height: 44px;
    text-align: center;
    font-size: 0.875rem;
    color: white; }
    input[type="submit"]:hover {
      opacity: 0.8; }
  textarea{
    margin-bottom: 0;
  }

      ul.gallery{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 0 1em;
        list-style-type: none;
        li {
          position: relative;
          width: 33.333%; padding-top: 25%;
          border: 1px solid #fff;
        }
        img{
          width: 100%; height: 100%;
        }
        li a {
          position: absolute;
          top: 0; left: 0;
          right: 0; bottom: 0;
        }
      }

      .bazar-info li:nth-child(1),
      .bazar-info li:nth-child(2) {
        display: inline-block;
        width: auto;
      }
      .bazar-info li:nth-child(1){
        margin-right: 1em;
      }
      .bazar-info li:nth-child(3) {
        width: 100%;
      }
      main section p, main section li {
        font-size: 1.125rem;
      }
}
