.pagenation{
  margin: 1em auto;
  text-align: center;
}

.single-pagenation,
.wp-pagenavi{
  display: flex;
  justify-content: center;
  .current {
  	background-color: $green;
  	color: $white;
  }
  a, span {
    position: relative;
    min-width: 2rem;
    margin: 0.25em;
  	padding: 0 0.5em;
    border: 1px solid $black;
    border-radius: $global-border-radius;
    line-height: 2rem;
  }
  a {
    @extend %fill-overlay;
    &:before{ border-radius: $global-border-radius; }
    &:hover:before { background-color: rgba($yellow, 0.3); }
  }
}
