body.scene{

  .page-header{
    justify-content: center;
    align-items: center;
  }
  .page-title img {
    max-height: 100px;
  }
}

.scene-post-container{
  @include breakpoint(medium up){
    margin-left: auto;
    margin-right: auto;
  };
  @include breakpoint(small down){
    margin-left: site-container-margin(small);
    margin-right: site-container-margin(small);
  };
}

.scene-post{
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  &__image{
    margin-top: 2em;
    margin-bottom: 2em;
  }
  img{
    width: 100%;
  }
}
