main.site-content{
  .wp-block-columns{
    justify-content: space-between;
  }
  @media (min-width: 600px){
    .wp-block-column {
    	flex-basis: calc(50% - 0.5em);
      margin: 0 0 1em;
    }
  }
}
