.shop-category-header{
  &{
    display: flex;
    justify-content: center;
    width: 100%;
    @include breakpoint(small down){
      padding: 0 2vw;
    };
  }
  &__title,
  &__count{
    margin: 0 0 1rem;
    padding: 1rem;
    padding-right: 2rem;
    border: 1px solid;
    border-radius: $global-border-radius;
    background-color: $white;
    line-height: 1rem;
    @include breakpoint(small down){
      line-height: (1rem/6*5);
      font-size: rem-calc(12);
    };
  }
  &__title{
    margin-left: auto;
    border-bottom-right-radius: 0;
    strong{
      line-height: 2rem;
      font-weight: normal;
      font-size: rem-calc(32);
    }
    b{ font-size: rem-calc(12); }
    b:not(:last-of-type):after{ content: " / " }
    @include breakpoint(small down){
      strong{
        line-height: (1rem/6*10);
        font-size: rem-calc(22);
      }
      b{ font-size: rem-calc(10); }
    };
  }
  &__count{
    margin-left: -1px;
    margin-right: auto;
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    align-self: flex-end;
  }
}

.shop-card-container:not(:last-of-type){
  margin-bottom: 2rem;
}
.shop-card-lists{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // background-color: $white;
  .shop-card-list{
    margin: 0 0.5vw 1vw;
    @include breakpoint(small down){ width: calc(50% - 0.5em); };
    @include breakpoint(medium only){ width: 25%; };
    @include breakpoint(large up){ width: 18%; };
  }
}

.shop-tag {
  @extend %ja-font-bold;
  display: inline-block;
  padding: 0 0.5em;
  background-color: $black;
  border-radius: 2px;
  line-height: 1rem;
  color: $white;
  @include breakpoint(small down){ font-size: rem-calc(10); };
  @include breakpoint(medium only){ font-size: rem-calc(11); };
  @include breakpoint(large up){ font-size: rem-calc(12); };
}

%border-box{
  border: 1px solid;
  background-color: $white;
  border-radius: $global-border-radius;
}
.shop-card{
  &{
    @extend %border-box;
    position: relative;
    display: flex;
    height: 100%;
  }
  a {
    @extend %fill-overlay;
    &:before{ border-radius: $global-border-radius; }
    &:hover:before { background-color: rgba($yellow, 0.3); }
  }
  &--column{
    flex-direction: column;
  }
  &--row{
    flex-direction: row;
    align-items: flex-end;
    .shop-card__thumb { width: 40%; }
    .shop-card__thumb img{ height: 9-0.125rem; }
    .shop-card__details { width: 60%; }
    @include breakpoint(small down){
      margin-left: site-container-margin(small);
      margin-right: site-container-margin(small);
    };
  }
  &--h1{
    width: 100%;
    .shop-card__thumb { width: 20%; }
    .shop-card__details { width: 80%; }
    @include breakpoint(medium only){
      margin-left: site-container-margin(medium);
      margin-right: site-container-margin(medium);
    };
  }
  &__title {
    margin: 0.25rem auto;
    line-height: 1.25rem;
    font-size: 1rem;
    &.h1{
      font-size: 2rem;
      line-height: 2rem;
      @include breakpoint(small down){
        font-size: 1.5rem;
        line-height: 1.5rem;
      };
    }
  }
  &__details{
    margin: 0.75rem;
    line-height: 1rem;
  }
  &__thumb {
    overflow: hidden;
    align-self: stretch;
    img {
      position: relative;
      top: 0.125rem; left: 0.125rem;
      width: 100%;
      border-radius: ($global-border-radius - 2px);
      @include breakpoint(small down){ height: 33.333vw; };
      @include breakpoint(medium only){ height: 6.875rem; };
      @include breakpoint(large up){ height: 10.875rem; };
    }
    .shop-card--h1 & img{
      @include breakpoint(small down){ height: 100%; };
    }
  }
  &__address{
    margin: 0;
    line-height: 1rem;
    @include breakpoint(small down){ font-size: rem-calc(11); };
    @include breakpoint(medium only){ font-size: rem-calc(12); };
    @include breakpoint(large up){ font-size: rem-calc(13); };
  }

}//end .shop-card

.shop-info{
  &{
    @extend %border-box;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    margin-top: 1rem;
    @include breakpoint(medium only){
      margin-top: site-container-margin(medium);
      margin-left: site-container-margin(medium);
      margin-right: site-container-margin(medium);
    };
    @include breakpoint(small down){
      flex-direction: column;
      margin-top: site-container-margin(small);
      margin-left: site-container-margin(small);
      margin-right: site-container-margin(small);
    };
  }
  &__title{
    margin: 0.25rem 0 0.5rem;
    padding: 0;
    border: none;
    font-size: rem-calc(14);
    color: $green;
  }
  &__photos{
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(medium up){ width: calc(40% - 1rem); };
    a{
      display: flex;
      align-items: center;
      width: 25%;
      border-right: 1px solid $black;
      background-color: $true-black;
      @include breakpoint(medium only){ width: 50%; };
    }
    a:first-of-type{
      width: 100%;
      border-bottom: 1px solid $black;
    }
    img{
      width: 100%;
    }
  }
  &__basic{
    @include breakpoint(medium up){ width: calc(60% - 1rem); };
    margin: 0.75rem 1rem;
    dl,ul{ margin-bottom: 0; }
    h3{ margin-top: 0.75rem; }
  }
  &__feeds{
    @include breakpoint(medium up){
      order: 1;
      align-self: flex-end;
      max-width: calc(40% - 1rem);
    };
  }
  &__description{
    @include breakpoint(medium up){
      order: 2;
      flex: 1 1 0px;
    };
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.shop-data{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  dt{
    width: 6em;
  }
  dd{
    width: calc(100% - 6.5em);
  }
}
.shop-item{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  &__title,
  &__fee{
    flex: 0 0 auto;
  }
  &__line{
    flex: 1 1 0;
    height: 0;
    margin: 0 1em;
    border-bottom: 1px dashed;
  }
}

.shop-entry{
  align-self: center;
  max-width: 36em;
  margin: auto;
  padding: 4rem 2rem;
  p{ text-align: justify; }
  @include breakpoint(small down){
    padding: 8vw;
  }
}
.shop-links{
  align-self: flex-end;
  max-width: 36em;
  padding: 0 2rem;
  @include breakpoint(small down){
    padding: 0 8vw;
  }
}

//アクセスマップと関連のある店舗
.shop-related{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem auto 0;
  @include breakpoint(small down){ flex-direction: column; };
  @include breakpoint(medium only){
    margin-left: site-container-margin(medium);
    margin-right: site-container-margin(medium);
  };

  &__map,
  &__yarpp{
    @include breakpoint(medium up){ width: calc(50% - 0.5rem); };
  }
  &__map{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .fukidashi-title{ align-self: flex-start; }
  }
  &__yarpp{
    @include breakpoint(medium up){ margin-bottom: 1rem; };
  }

}// end .shop-related

.gmap-container{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid $black;
  border-radius: $global-border-radius;
  @include breakpoint(small down){
    margin-left: site-container-margin(small);
    margin-right: site-container-margin(small);
  }
  &,iframe{
    flex: 1 1 auto;
  }
}

.yarpp-related{
  margin: 0 !important;
  &__lists{
    margin-bottom: 0;
  }
  &__list:not(:last-of-type){
    margin-bottom: 0.5rem;
  }
}
