.off-canvas{
  @include breakpoint(large up){
    display: none;
  };
  &__trigger{
    @extend %ja-font-bold;
    position: fixed; z-index: 256;
    top: 5px; left: 5px;
    width: 46px; height: 50px;
    margin: 0 !important; padding: 26px 0;
    border-radius: $global-border-radius;
    background-color: $white;
    text-align: center;
    letter-spacing: -1px;
    font-size: rem-calc(10);
    span:before{ content: 'メニュー'; }
    &:before, &:after{
      content: '';
      position: absolute;
      left: 8px;
      width: 30px;
    }
    &:before{
      top: 8px;
      height: 14px;
      background-color: $black;
    }
    &:after{
      top: 10px;
      height: 10px;
      border-top: 4px solid $white;
      border-bottom: 4px solid $white;
    }
    &:hover{
      &:before{ background-color: $white; }
      &:after{ border-color: $green; }
      background-color: $green;
      color: $white;
    }
  }
  input{
    display: none;
    &:checked + .off-canvas__trigger{
      &:before{ top: 10px; height: 10px; }
      span:before{ content: '閉じる'; }
    }
  }
}

/*選択されているタブのコンテンツのみを表示*/
#off_canvas_trigger:checked ~ .off-canvas-nav {
  padding: 1em 1em 1em 80px;
  height: 100%;
}
.off-canvas-nav{
  &{
    -webkit-overflow-scrolling: touch;
    position: fixed; z-index: 255;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    width: 100vw;
    height: 0;
    padding: 0;
  }
  &__lists,
  &__list--second{
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em auto 1em;
    flex-wrap: wrap;
    &{ width: 100%; }
    ul { margin: 0 calc(1em - 2px) 0 0; }
  }
  &__label{ width: 100%; }

  &__list--second{
    margin-bottom: 0;
  }

  a{
    display: inline-block;
    margin: 0 2px 2px 0;
    padding: 0.5em 1em;
    border: 1px solid;
    border-radius: $global-border-radius;
    line-height: 1rem;
  }
}
