

$grid-column-count: 12;
// $grid-column-gutter: (
//   small: 8px,
//   medium: 16px,
// );
$header-font-weight: bold;
$header-styles: (
  small: (
    'h1': ('font-size': 18),
    'h2': ('font-size': 16),
    'h3': ('font-size': 14),
    'h4': ('font-size': 14),
    'h5': ('font-size': 14),
    'h6': ('font-size': 14),
  ),
  medium: (
    'h1': ('font-size': 18),
    'h2': ('font-size': 16),
    'h3': ('font-size': 14),
    'h4': ('font-size': 14),
    'h5': ('font-size': 14),
    'h6': ('font-size': 14),
  ),
);
// 11. Button
// ----------

$defnlist-term-margin-bottom: 0;

//$button-background: $accent-color;
$button-padding: 0.75em 2em;
//$button-background-hover: scale-color($button-background, $lightness: 15%);
