.breadcrumbs{
  display: flex;
  align-items: center;
  min-height: 2rem;
  line-height: 1;
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  @include breakpoint(medium down){
    margin-left: site-container-margin(small);
  };
  &--footer{
    margin-bottom: 1rem;
    @include breakpoint(medium up){
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    };
  }
  & > *{
    flex: 0 0 auto;
  }
  & > .current-item,
  & > span > a,
  & > span > span{
    display: inline-block;
    position: relative;
    margin-right: 0.5em;
    padding: 0.5em 0.75em;
    border: 1px solid;
    border-radius: $global-border-radius;
    background-color: $white;
    font-size: rem-calc(12);
    color: $black;
  }
  & > .current-item,
  & > span:last-of-type > span{
    margin-right: 0;
  }
  & > span > a:after{
    content: "";
    transform: rotate(45deg);
    transform-origin: center;
    position: absolute; z-index: 1;
    top: calc(50% - 0.3em);
    right: -0.35em;
    display: inline-block;
    width: 0.6em; height: 0.6em;
    border-top: 1px solid;
    border-right: 1px solid;
    background-color: $white;
  }
  a {
    @extend %fill-overlay;
    &:before{ border-radius: $global-border-radius; }
    &:hover:before { background-color: rgba($yellow, 0.3); }
    &:hover:after { background-color: #f4e8b2; }
  }
}
