.feed-tab-ui{
  &{
    display: flex;
    flex-wrap: wrap;
    min-width: 300px;
    margin: -1px;
  }
  input{
    display: none;
    &:checked + .feed-tab-ui__tab{
      overflow: hidden;
      position: relative; z-index: 1;
      align-self: flex-end;
      height: 2.25em;
      background-color: $green;
      border-bottom-color: $green;
      label{ color: $white; }
    }
  }
  &__tab{
    order: 1;
    margin: 0.75rem -1px -1px 0;
    border: 1px solid $black;
    border-top-left-radius: $global-border-radius;
    border-top-right-radius: $global-border-radius;
    label{
      padding: 0 1em;
      line-height: 2rem;
    }
  }
  &__content{
    order: 2;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 0;
    border: none;
    border-top-right-radius: $global-border-radius;
  }
  /*選択されているタブのコンテンツのみを表示*/
  #facebook_feed:checked ~ #facebook_feed_content,
  #twitter_feed:checked ~ #twitter_feed_content,
  #blog_feed:checked ~ #blog_feed_content {
    height: 500px;
    border: 1px solid;
  }
}

.blog-feed{
  &__title{
    margin: 0;
    font-size: rem-calc(16);
    line-height: 1.25rem;
    a{
      display: block;
      padding: 1rem 0.5rem;
      background-color: lighten($black, 63%);
    }
  }
  &__lists{
    margin: 0;
  }
}

.feed-item{
  &{
    position: relative;
    padding: 0.5rem;
    border-top: 1px solid lighten($black, 50%);
    &:hover{
      background-color: lighten($blue, 50%);
    }
  }
  &__title{
    margin: 0;
    line-height: 1rem;
    font-size: rem-calc(14);
  }
  &__description{
    line-height: 1rem;
    font-size: rem-calc(12);
  }
  &__footer{
    line-height: 1rem;
    font-size: rem-calc(11);
    text-align: right;
  }
  a{
    color: $blue;
  }
  a:before{
    content: "";
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
}
