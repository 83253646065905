
.container {
  padding-top: 100px;
  background: url(../images/base/illust_yurinoki-st-n.jpg) repeat-x;
  background-size: auto 84px;
  background-position: 0 top;
}

.site-title {
  margin: 0 0 1.5em;
  img{ width: 444px; }
  @include breakpoint(small down){
    text-align: center;
  }
}

.tagline {
  margin-bottom: 0;
  letter-spacing: 0.25em;
  font-size: rem-calc(13);
  color: $black;
  @include breakpoint(small down){
    margin-bottom: 0.5em;
    text-indent: 1.666vw;
    letter-spacing: 1.666vw;
    font-size: rem-calc(10);
  }
}

.hitokoma {
  &{
    background: url(../images/base/illust_header.png) no-repeat left bottom;
    background-size: 83px 64px;
    padding: 6px 14px 6px 72px;
  }
  header & {
    position: absolute;
    top: -140px; right: 0;
    @include breakpoint(medium only){ top: calc(10vw - 240px); };
    @include breakpoint(small down){ display: none; };
    &__fukidashi {
      position: absolute;
      top: -0.5em; right: calc(100% - 3em);
      white-space: nowrap;
    }
  }
  &__fukidashi {
    @include breakpoint(medium up){
      font-size: font-size(14);
    }
    @include breakpoint(small down){
      font-size: rem-calc(10);
      line-height: 10px;
    };
  }
  a:hover {
    opacity: 0.8; }
}
