
#main-nav{
  position: relative;
}

.global-nav {
  &{
    $roof-margin: 30px;
    margin-top: $roof-margin;
    a:before, a:after { margin-top: -$roof-margin; }
    @include breakpoint(small down){
      $roof-margin: 5vw;
      margin-top: $roof-margin;
      a:before, a:after { margin-top: -$roof-margin; }
    };
  }
  a {
    @extend %ja-font-bold;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    line-height: 1.25;
    color: $white;
    @include breakpoint(medium down){
      flex-direction: column;
      text-align: center;
      padding: 0;
    };
    @include breakpoint(small down){
      font-size: rem-calc(10);
    };
  }
  a > img {
    max-width: 30px;
    @include breakpoint(large up){ margin: 0.5em; };
    @include breakpoint(small down){ width: 7.5vw; };
  }
  a > span {
    margin: 0.5em;
    @include breakpoint(small down){ margin: 0.5em; };
  }
  a:before,
  a:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }
  a:before {
    top: 4px; bottom: -2px;
    left: 3px; z-index: -1;
    background-color: $yellow;
  }
  a:last-of-type:before{ left: 2px; width: calc(100% - 5px); }
  a:hover:before {
    background-color: $green;
  }
  a:after {
    width: calc(100% + 1px);
    border: 1px solid $black;
    background-color: transparent;
  }
  a:last-of-type:after{ width: calc(100% - 0px); }
  body.event & .event:before,
  body.shop-category & .shop:before,
  body.shop & .shop:before,
  body.access & .access:before,
  body.about & .about:before,
  body.contact & .contact:before,
  body.bazar & .bazar:before
    { background-color: $green; }
}

%fill-overlay{
  &:before{
    content: "";
    position: absolute;;
    top: 2px; bottom: 0;
    left: 2px; right: 0;
  }
}
.shop-category-nav {
  &{ margin-top: -1px; }
  &__list {
    position: relative;
    padding: 0.75rem;
    border: 1px solid;
    border-bottom-left-radius: $global-border-radius;
    border-bottom-right-radius: $global-border-radius;
    line-height: 1rem;
    span { display: block; font-size: rem-calc(10); }

    @include breakpoint(large up){
      margin-right: -1px; width: calc(16.666% + 1px);
      &:last-child{ margin-right: 0; width: 16.666%; }
    };
    @include breakpoint(medium only){
      margin-right: -1px; width: calc(33.333% + 1px);
      &:nth-child(3n){ margin-right: 0; width: 33.333%; }
      &:nth-child(-n+3){ margin-bottom: -1px; border-radius: 0;
        a:before{ border-radius: 0; } }
    };
    @include breakpoint(small down){
      width: 50%;
      padding: 0.5rem 2vw;
      &:nth-child(odd){ margin-right: -1px; width: calc(50% + 1px); }
      &:nth-child(-n+4){ margin-bottom: -1px; border-radius: 0;
        a:before{ border-radius: 0; } }
      span{ letter-spacing: -1.25px; }
    };
  }
  &__list:not(:last-child){
    //border-right: none;
  }
  a {
    @extend %fill-overlay;
    color: $black;
  }
  a:before{
    border-bottom-left-radius: $global-border-radius;
    border-bottom-right-radius: $global-border-radius;
  }
  a:hover:before {
    background-color: rgba($yellow, 0.3);
  }
  body.eat & .eat,
  body.wear & .wear,
  body.buy & .buy,
  body.learn & .learn,
  body.care & .care,
  body.use & .use,
    {
      background-color: $green;
      & a{ color: $white; }
      & a:before{ display: none; }
    }
}
