
.archive-nav-container{
  @include breakpoint(small down){
    order:10;
    margin-top: 1em;
  };
}

.archive-nav{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title,
  &__list{
    border: 1px solid;
    border-radius: $global-border-radius;
  }
  &__title{
    position: relative; z-index: 1;
    margin: 0 0 -1px;
    padding: 0 0.5em;
    background-color: $white;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &__list{
    width: 100%;
    border-top-left-radius: 0;
  }
  ul{
    padding: 1em 0;
  }
  li a{
    display: block;
    padding: 0 1em;
  }
}

.yearly-archive-list{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5em -1px 0.5em 0.5em;
  &__title{
    font-size: rem-calc(13);
  }
  &__inner{
    display: flex;
    flex-wrap: wrap;
    padding: 0.25em 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  dd{
    padding: 0.25em;
    padding-left: 0.5em;
    flex: 0 0 auto;
  }
}
