%ja-font{
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-weight: 400;
  font-style: normal;
}

%ja-font-bold{
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-weight: 600;
  font-style: normal;
}

$body-font-family: dnp-shuei-mgothic-std, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
