
img{
  overflow: hidden;
  vertical-align: bottom;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  &.contain{
    -o-object-fit: contain;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }
}

iframe {
  max-width: 100%;
  vertical-align: bottom;
}

main > .column, main > .columns{
  @include breakpoint(small down){
    padding-left: site-container-margin(small);
    padding-right: site-container-margin(small);
  };
}


.more {
  &{
    text-align: center; }
  a {
    @extend %fill-overlay;
    position: relative;
    display: inline-block;
    padding: 0 1.5em;
    border: 1px solid $black;
    border-radius: 15px;
    line-height: 30px;
    font-weight: bold;
  }
  a:before{
    border-radius: 15px;
    background-color: rgba($yellow, 0.3);
  }
  a:hover {
    background-color: $green;
    color: $white; }
  a:hover:before {
    background-color: rgba($yellow, 0); }
}

.fukidashi-title {
  &{
    display: inline-block;
    position: relative;
    padding: 0.5rem 1rem 0.5rem 1em;
    border: 1px solid $black;
    line-height: 1rem;
    border-radius: 1rem;
    background-color: $white;
    font-weight: normal;
    font-size: rem-calc(14);
    color: $black; }
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before{
    bottom: -8px; right: -6px;
    width: 1em; height: 20px;
    border-top: 3px solid $black;
    border-radius: 15px;
  }
  &:after{
    top: 1px; bottom: 1px;
    right: 0;
    width: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: $white;
  }
}
