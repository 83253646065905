%round-roof{
  background-color: $white;
  border-top: 1px solid $black;
  border-top-left-radius: 50vw 6vw;
  border-top-right-radius: 50vw 6vw;
  @include breakpoint(small down){
    border-top-left-radius: 50vw 12vw;
    border-top-right-radius: 50vw 12vw;
  };
}

body#home{

  // background-color: $green; //lighten($yellow, 33%);
  background: url(../images/base/illust_yurinoki-st-n.jpg) repeat-x;
  background-size: auto 83px;
  background-position: center top;

  .container {
    @extend %round-roof;
    margin-top: 1vw;
    padding-top: 4em;
    background-image: none;
    @include breakpoint(small down){
      padding-top: 12vw;
    };
  }
  .container__header {
    position: relative; z-index: 1;
    margin-bottom: -.5vw;
  }
  .site-title, .tagline{
    text-align: center;
    font-size: rem-calc(14);
    @include breakpoint(small down){
      font-size: rem-calc(10);
    };
  }
  .site-title{
    margin-bottom: 3em;
    img { width: 42em; }
  }
  .tagline {
    margin-bottom: 0.5em;
    text-align: center;
    @include breakpoint(large up){
      text-indent: 1em;
      letter-spacing: 1em;
    };
    @include breakpoint(small down){
      text-indent: 1.666vw;
      letter-spacing: 1.666vw;
    };
  }

}

@keyframes background-loop--left {
  0% { background-position: 0 center; }
  100% {background-position: -150% center; }
}
@keyframes background-loop--right {
  0% { background-position: 0 center; }
  100% {background-position: 150% center; }
}

.home-cover{
  $size: 150vw;
  &__left, &__right{
    overflow: hidden;
    width: 100vw;
    animation: none 60s linear 0s infinite;
    animation-play-state: paused;
    background-size: $size auto;
    background-repeat: repeat-x;
    .home-cover:hover &{ animation-play-state: running; }
  }
  &__left{
    animation-name: background-loop--left;
    background-image: url(../images/base/illust_yurinoki-st-n.jpg);
  }
  &__right{
    animation-name: background-loop--right;
    background-image: url(../images/base/illust_yurinoki-st-s.jpg);
  }
  img{
    display: block;
    visibility: hidden;
    width: $size*1.25; height: auto;
    max-width: none;
  }
  @include breakpoint(medium only){
    $size: 200vw;
    background-size: $size auto;
    &__left, &__right{ background-size: $size auto; }
    img{ width: $size*1.25; }
  };
  @include breakpoint(small down){
    $size: 300vw;
    background-size: $size auto;
    &__left, &__right{ background-size: $size auto; }
    img{ width: $size; }
  };
}

.home-scene-container{
  position: relative;
  .scene{
    overflow: hidden;
    &__image{
      transform: rotate(5deg);
      transform-origin: center;
      max-width: 280px;
      margin: auto;
    }
    @include breakpoint(medium up){
      position: absolute;
      top: -25vw; right: 1vw;
      max-width: 280px;
    };
    @include breakpoint(small down){
      margin-top: -10vw;
    };
  }
  .scene__image a{
    transition: transform 0.2s ease-out;
    display: block;
    border: 10px solid #fcfefc;
    border-radius: $global-border-radius;
    box-shadow: 0px 0px 30px -12px;
    color: $black;
    &:hover{
      transform: translateY(-10px);
    }
  }
  .hitokoma{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 0;
    &__fukidashi{
      transform: rotate(5deg);
      transform-origin: center;
      left: -72px * 1.25;
      margin: 0;
      font-size: rem-calc(10);
      line-height: 1em;
    }
    @include breakpoint(small down){
      background-position: calc(50vw - 180px) bottom;
      h2{ margin: 0; }
      &__fukidashi{
        left: -72px * 2;
      }
    };
  }
}

.home-event-info{
  @extend .no-bullet;
  &{
    display: flex;
    justify-content: center;
    @include breakpoint(small down){
      flex-direction: column;
      padding-left: 2vw;
    };
  }
  &__list{
    display: flex;
    flex-direction: column;
    @include breakpoint(small down){
      align-items: center;
    };
  }
  &__title{
    margin-right: 1em;
    margin-bottom: 0.5em;
    @include breakpoint(small down){
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      max-width: 100%;
    };
    &:hover,
    &:hover:after
    { background-color: $green; color: $white; }
  }
  &__date{
    font-size: rem-calc(12);
  }
}

.home-info-container,
.home-shop-container{
  @extend %round-roof;
  position: relative;
  margin-top: -3vw;
  @include breakpoint(small down){
    margin-top: -280px;
  };
}

.home-info-container{
  margin-bottom: 3vw;
  padding: 3vw 2vw;
  .wp-block-button__link{ font-size: 1em; }
  @include breakpoint(small down){
    margin-bottom: 280px;
    .wp-block-button__link{ font-size: 0.8em; }
  };
}
.home-shop-container{
  padding: 2vw 1vw 0;
}
