$true-white: #fff;
$true-black: #000;
$white: $true-white;
$black: #5a5a58;

$yellow: #e4c009;
// $yellow: #f7d116;
$green: #30a514;
$blue: #316cbb;

$primary-color: $black;
$anchor-color: $primary-color;
$anchor-color-hover: $green;
