
.container__footer {
  padding: 2rem 0 83px;
  background: url(../images/base/illust_yurinoki-st-s.jpg) repeat-x center bottom;
  background-size: auto 83px;
}

.footer-nav {
  margin-bottom: 2em;
  text-align: center;
}

.copyright {
  text-align: center;
  font-size: rem-calc(12);
}
